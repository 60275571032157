import React from "react"
import { Container } from "react-bootstrap"
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class BannerSection extends React.Component {
  
  render() {
    return (
      <section className="banner text-center">
        <Container>
                <h1 className="main-heading text-center">About Us </h1>
                <p className="pharagraph">
                We, Coinsclone offer a great deal of modern solutions related to blockchain and Cryptocurrency. Our futuristic thoughts and client-centric approach helped us excel in a wide range of services including Crypto Exchange development, NFT Marketplace development, Crypto Payment Gateway development, Crypto Wallet development, and many more.
                </p>
                <p className="pharagraph">
                Having worked with more than 600 clients around the globe, Coinsclone stands out from the rest in delivering White Label solutions and MVP products. By connecting with us, you can be accustomed to a skilled set of developers who thrive on the latest Web3-based technologies. 
                </p>
                <p className="pharagraph">
                Drop your business query and reach us for all kinds of cryptocurrency-related services. 
                </p>
                <div className="cta-banner justify-content-center">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
        </Container>
      </section>
    )
  }
}

export default BannerSection
