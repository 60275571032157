import * as React from "react"

import Layout from "../components/layout"
import BannerSection from "../components/Aboutus/Banner"
import { Helmet } from "react-helmet"
// import SecurityofOur from "../components/Aboutus/SecurityofOur"
// import CoreFeatures from "../components/Aboutus/CoreFeatures"
// import WhyChoose from "../components/Aboutus/WhyChoose"
// import WhatMake from "../components/Aboutus/WhatMake"
// import UserFriendly from "../components/Aboutus/UserFriendly"
import PortfolioSection from "../components/Aboutus/Portfolio"


const Aboutus = () => (
  <div className="abtus">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/about-us/" />
        <title>About Us | Cryptocurrency Exchange Service Provider | Coinsclone</title>
        <meta name="description" content="Coinsclone, a leading cryptocurrency exchange service provider offers services such as Clone Scripts for Cryptocurrency Exchange and Crypto Payment Gateway." />
        <meta name="keywords" content="About Coinsclone, Cryptocurrency Exchange Script, Bitcoin Exchange Script, White label Cryptocurrency Exchange Software, P2P Crypto Exchange Software, OTC Exchange Software" />
      </Helmet>
      <BannerSection />
      
      {/* <SecurityofOur />
      <CoreFeatures />
      <WhatMake />
      <WhyChoose /> */}
      <PortfolioSection />
      {/* <UserFriendly /> */}
    </Layout>
  </div>
)

export default Aboutus
